//@ts-check
import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlListarPedidos, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";

export function converterDataFormato(dataOriginal: { dataOriginal: String }) {
  //@ts-ignore
  const partes = dataOriginal.split(" ");
  const dataPartes = partes[0].split("-");
  const horaPartes = partes[1].split(":");

  const dia = dataPartes[2];
  const mes = dataPartes[1];
  const ano = dataPartes[0];

  const hora = horaPartes[0];
  const minutos = horaPartes[1];

  const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

  return dataFormatada;
}

export const TableDados = ({ nome, status, inicio, fim, clienteId }) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarContatos = async () => {
    try {
      const response = await axios.post(
        `${urlListarPedidos}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
          status: status,
          inicio: inicio,
          fim: fim,
          clienteId: clienteId
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
    // listarContatos(newPage)
    //   .then((lista) => {
    //     const contatosFiltrados = lista.filter((item) =>
    //       item.nome_cliente.toLowerCase().includes(nome.toLowerCase())
    //     );
    //     setContatos(contatosFiltrados);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const teste = async () =>{
    try {
      const response = await axios.post('https://apis.gerenciadores.com.br/marmita_express_app/apis/administrador/clientes/clientes_ativos?pagina=1', {
          token: tokenUsuario,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const alterarContato = (event) => {
    navigate({
      pathname: "/pedidos/alterar",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  useEffect(() => {
    teste();
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos(pagina);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [pagina]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos();
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [nome, status, inicio, fim]);

  const statusTextArray = [
    { id: "1", nome: "Pendente - Em preparação" },
    { id: "2", nome: "Em andamento" },
    { id: "3", nome: "Aguardando confirmação de pagamento" },
    { id: "4", nome: "Finalizado" },
    { id: "5", nome: "Cancelado" },
    { id: "6", nome: "Reembolsado" },
    { id: "7", nome: "No carrinho" },
  ];

  const cellStatus = (status) => {
    const statusObj = statusTextArray.find((item) => item.id == status);
    return <span>{statusObj ? statusObj.nome : ""}</span>;
  };

  const cellTipo = (tipo) => {
    let tipoPedido = tipo;
    if (tipoPedido === 0) {
      return <span>{"Manual"}</span>;
    } else {
      return <span>{"Aplicativo"}</span>;
    }
  };

  const cellValor = (valor) => {
    return <span>R$ {valor}</span>;
  };

  const cellData = (data) => {
    return <span>{converterDataFormato(data)}</span>;
  };

  const columns = [
    { field: "id", headerName: "#", width: 80 },
    { field: "nome_cliente", headerName: "Cliente", width: 180 },
    { field: "nome_empresa", headerName: "Empresa", width: 180 },

    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => cellStatus(params.value),
      width: 200,
    },
    {
      field: "feito_aplicativo",
      headerName: "Tipo",
      renderCell: (params) => cellTipo(params.value),
      width: 100,
    },
    {
      field: "forma_pagamento",
      headerName: "F. de Pagamento",
      width: 150,
    },
    {
      field: "data",
      headerName: "Data",
      renderCell: (params) => cellData(params.value),
      width: 200,
    },
    {
      field: "valor",
      headerName: "Valor",
      renderCell: (params) => cellValor(params.value),
      width: 100,
    },
  ];

  return (
    <>
      <div style={{ width: "100%", overflow: "hidden" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={contatos}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            //@ts-ignore
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
          />
        )}
      </div>
    </>
  );
};
