import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './pages/inicio/index';
import Login from './pages/login';
import RelatorioPedidos from './pages/relatorio_pedidos';
import RelatorioPedidosClientes from './pages/relatorios_pedidos_clientes';
import RelatorioPedidosEmpresas from './pages/relatorios_pedidos_empresas';

import RelatorioPedidosFormaPagamento from './pages/relatorios_pedidos_forma_pagamento';
import RelatorioMarmitasEmpresa from './pages/relatorio_marmitas_por_empresa';

import Produtos from './pages/produtos';
import AdicionarProduto from './pages/produtos/adicionar';
import AlterarProduto from './pages/produtos/alterar';

import Ingredientes from './pages/ingredientes';
import AdicionarIngredientes from './pages/ingredientes/adicionar';
import AlterarIngredientes from './pages/ingredientes/alterar';

import EnderecosNatendidos from './pages/enderecos_nao_atendidos';
import AdicionarEnderecosNatendidos from './pages/enderecos_nao_atendidos/adicionar';
import AlterarEnderecosNatendidos from './pages/enderecos_nao_atendidos/alterar';

import Categorias from './pages/categorias';
import AdicionarCategoria from './pages/categorias/adicionar';
import AlterarCategoria from './pages/categorias/alterar';

import Empresas from './pages/empresas';
import AdicionarEmpresas from './pages/empresas/adicionar';
import AlterarEmpresas from './pages/empresas/alterar';

import Clientes from './pages/clientes';
import AdicionarClientes from './pages/clientes/adicionar';
import AlterarClientes from './pages/clientes/alterar';
import ClientesAtivos from './pages/clientes_ativos';

import FormasDePagamento from './pages/forma_pagamentos';
import AdicionarFormasDePagamento from './pages/forma_pagamentos/adicionar';
import AlterarFormasDePagamento from './pages/forma_pagamentos/alterar';

import AdicionarPedidos from './pages/pedidos/adicionar';
import AlterarPedido from './pages/pedidos/alterar';
import Pedidos from './pages/pedidos';

import NotFound from './pages/not_found';

import Avancado from './pages/avancado';

import CodigoVerificacao from './pages/codigo_verificacao';

import Administradores from './pages/admin';
import AdicionarAdministradores from './pages/admin/adicionar';
import AlterarAdministradores from './pages/admin/alterar';

//Whatsapp

import Chat from './pages/chat';
import Atendimentos from './pages/atendimentos';
import InformacoesAtendimento from './pages/atendimentos/informacoes';
import DiasNaoAtendidos from './pages/dias_nao_atendidos';
import EnviarMensagensMassa from './pages/disparos/enviar';
import AvancadoW from './pages/avancado_w';
import Disparos from './pages/disparos';
import AlterarDisparos from './pages/disparos/alterar';
import AdicionarDisparos from './pages/disparos/adicionar';
import DetalheDisparos from './pages/disparos/detalhe';
import Metricas from './pages/metricas';

import Aplicativo from './pages/app';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

export default function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const [themeMui, setThemeMui] = useState(lightTheme);
  
  const theme = localStorage.getItem('theme');

  useEffect(() => {
    if (theme === 'dark') {
      document.body.setAttribute('data-bs-theme', 'dark');
      setThemeMui(darkTheme);
    } else {
      document.body.removeAttribute('data-bs-theme');
      setThemeMui(lightTheme);
    }
  }, [theme]);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const appliedTheme = storedTheme === 'dark' ? darkTheme : lightTheme;
    setThemeMui(appliedTheme);
  }, [theme]);
  
  return (
    <ThemeProvider theme={themeMui}> {/* Aplicando o tema */}
      <CssBaseline />
      <Router>
        <Routes>

          <Route path="/" element={<Inicio />} />

          <Route path="/inicio" element={<Inicio />} />

          <Route path="/login" element={<Login />} />

          <Route path="/relatorios_pedidos" element={<RelatorioPedidos />} />
          <Route path="/relatorios_pedidos_clientes" element={<RelatorioPedidosClientes />} />
          <Route path="/relatorios_pedidos_empresas" element={<RelatorioPedidosEmpresas />} />
          <Route path="/relatorios_pedidos_forma_pagamento" element={<RelatorioPedidosFormaPagamento />} />
          <Route path="/relatorios_marmitas_por_empresa" element={<RelatorioMarmitasEmpresa />} />

          <Route path="/produtos" element={<Produtos />} />
          <Route path="/produtos/adicionar" element={<AdicionarProduto />} />
          <Route path="/produtos/alterar" element={<AlterarProduto />} />

          <Route path="/categorias" element={<Categorias />} />
          <Route path="/categorias/adicionar" element={<AdicionarCategoria />} />
          <Route path="/categorias/alterar" element={<AlterarCategoria />} />

          <Route path="/ingredientes" element={<Ingredientes />} />
          <Route path="/ingredientes/adicionar" element={<AdicionarIngredientes />} />
          <Route path="/ingredientes/alterar" element={<AlterarIngredientes />} />
          
          <Route path="/enderecos_nao_atendidos" element={<EnderecosNatendidos />} />
          <Route path="/enderecos_nao_atendidos/adicionar" element={<AdicionarEnderecosNatendidos />} />
          <Route path="/enderecos_nao_atendidos/alterar" element={<AlterarEnderecosNatendidos />} />

          <Route path="/forma_pagamentos" element={<FormasDePagamento />} />
          <Route path="/forma_pagamentos/adicionar" element={<AdicionarFormasDePagamento />} />
          <Route path="/forma_pagamentos/alterar" element={<AlterarFormasDePagamento />} />

          <Route path="/empresas" element={<Empresas />} />
          <Route path="/empresas/adicionar" element={<AdicionarEmpresas />} />
          <Route path="/empresas/alterar" element={<AlterarEmpresas />} />

          <Route path="/clientes" element={<Clientes />} />
          <Route path="/clientes/adicionar" element={<AdicionarClientes />} />
          <Route path="/clientes/alterar" element={<AlterarClientes />} />
          <Route path="/clientes/ativos" element={<ClientesAtivos />} />
          
          <Route path="/pedidos/adicionar" element={<AdicionarPedidos />} />
          <Route path="/pedidos" element={<Pedidos />} />
          <Route path="/pedidos/alterar" element={<AlterarPedido />} />

          <Route path="/admin" element={<Administradores />} />
          <Route path="/admin/adicionar" element={<AdicionarAdministradores />} />
          <Route path="/admin/alterar" element={<AlterarAdministradores />} />

          <Route path="/avancado" element={<Avancado />} />

          <Route path="/codigo_verificacao" element={<CodigoVerificacao />} />

          <Route path="*" element={<NotFound />} />

          {/* Whatsapp */}

          <Route path="/app" element={<Aplicativo />} />

          <Route path="/chat" element={<Chat />} />
          <Route path="/atendimentos" element={<Atendimentos />} />
          <Route path="/atendimentos/informacoes" element={<InformacoesAtendimento />} />
          
          <Route path="/dias_nao_atendidos" element={<DiasNaoAtendidos />} />
          
          <Route path="/avancado_w" element={<AvancadoW />} />
          
          <Route path="/disparos" element={<Disparos />} />
          <Route path="/disparos/alterar" element={<AlterarDisparos />} />
          <Route path="/disparos/adicionar" element={<AdicionarDisparos />} />
          <Route path="/disparos/disparar_mensagens" element={<EnviarMensagensMassa />} />
          <Route path="/disparos/detalhe" element={<DetalheDisparos />} />
          
          <Route path="/metricas" element={<Metricas />} />
          
        </Routes>
      </Router>
    </ThemeProvider>
  );
}