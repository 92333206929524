/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  urlListarClientesAtivos,
  urlEditarClienteAtivos,
  tokenUsuario,
} from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";

export const TableDados = ({ nome, empresa, totalValor }) => {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarClientes = async () => {
    let filtroEmpresa = empresa;
    if (empresa !== null) {
      filtroEmpresa = empresa.id;
    } else {
      filtroEmpresa = "";
    }
    try {
      const response = await axios.post(
        `${urlListarClientesAtivos}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          cliente: nome,
          empresa: filtroEmpresa,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
  };

  const alterarNumero = async (id, numero) => {
    try {
      const response = await axios.post(urlEditarClienteAtivos,
        {
          token: tokenUsuario,
          id: id,
          numero: numero,
        }
      );
      console.log(response);
    } catch (error) {
      return null;
    }
  };

  const processRowUpdate = async (event) => {
    await alterarNumero(event.id, event.value);
    listarClientes();
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarClientes(pagina);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setClientes(lista.clientes);
      totalValor(lista.total);
      setLoading(false);
    };
    run();
  }, [pagina]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarClientes();
      setClientes(lista.clientes);
      totalValor(lista.total);
      setLoading(false);
    };
    run();
  }, [nome, empresa]);

  const cellTelefone = (row) => {
    if(row.ddd && row.telefone){
      return `${row.ddd}${row.telefone}`;
    } else {
      return `Sem número`;
    }
  };

  const columns = [
    { field: "cliente_nome", headerName: "Nome", width: 255 },
    { field: "cliente_empresa", headerName: "Empresa", width: 255 },
    {
      field: "cliente_numero",
      headerName: "Número",
      //renderCell: (params) => cellTelefone(params.row),
      editable: true,
      width: 150,
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={clientes}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onCellEditCommit={processRowUpdate}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
          />
        )}
      </div>
    </>
  );
};
